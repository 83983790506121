import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import "./footer.css"
import PrimaryButton from './PrimaryButton';
import "../styles/global.css"

const Footer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "top-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <footer className="Footer-container">
      <div className="Pivot-width-container Footer-container-inner">
        <div className="Footer-column Footer-column-desktop">
          <div className="Footer-logo">
            <Img fluid={data.logo.childImageSharp.fluid} />
          </div>
        </div>
        <div className="Footer-column">
          <div className="Footer-column-head">Explore</div>
          <ul>
            <li><Link to="/athletes">Athletes</Link></li>
            <li><Link to="/organizations">Companies</Link></li>
            <li><Link to="/how-it-works">How it works</Link></li>
            <li><Link to="/made-by">Made by our athletes</Link></li>
            <li><Link to="/about">About</Link></li>
          </ul>
        </div>
        <div className="Footer-column">
          <div className="Footer-column-head">Questions?</div>
          <ul>
            <li><Link to="/faq">FAQ</Link></li>
            <li><a href="mailto:kristina@the-pivot.com">Contact Us</a></li>
            <li><a href="/terms" target="_blank">Terms of Service</a></li>
            <li><a href="/privacy" target="_blank">Privacy Policy</a></li>
          </ul>
        </div>
        <div className="Footer-column">
          <div className="Footer-column-head">Follow</div>

          <ul>
            <li><a href="http://instagram.com/thepivot_" target="_blank">Instagram</a></li>
            <li><a href="http://twitter.com/thepivot_" target="_blank">Twitter</a></li>
          </ul>
        </div>
        <div className="Footer-column">
          <div>
            <div className="Footer-column-head">Join</div>

            <div className="Footer-join">
              <a href="/app/register?type=athlete">
                <PrimaryButton>Athlete</PrimaryButton>
              </a>
              <a href="/app/register?type=organization">
                <PrimaryButton>Company</PrimaryButton>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer-copyright">© 2020 The Pivot Sports Group</div>
    </footer>
  );
}

export default Footer;
