import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Location } from "@reach/router";
import Img from "gatsby-image"
import _ from "lodash";
import "./header.css"
import "../styles/global.css"

const Header = ({ siteTitle }) => {

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "top-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [selectedPage, setSelectedPage] = useState(null);
  const [showNav, setShowNav] = useState(null);
  useEffect(() => {
    if (window) {

      // Update the document title using the browser API
      setSelectedPage(window.location);
    }
  }, []);

  const renderUnderline = (isUnderlined) => {
    if (isUnderlined) {
      return (
        <div className="header-underline"></div>
      )
    }
  }

  return (
    <Location>
      {
        locationProps => {
          console.log(locationProps.location.pathname);
          return (
            <header
              style={{
                background: "#fff"
              }}
            >
              <div
                className="header-inner-wrapper"
              >
                <div className="header-logo-wrapper">
                  <div className="header-logo">
                    <Link to="/">
                      <Img fluid={data.logo.childImageSharp.fluid} />
                    </Link>
                  </div>


                </div>
                <div className="mobile-nav">
                <button className="header-menu" onClick={() => setShowNav(true)}>☰ Menu</button>
                </div>
                <nav className={`${showNav ? "header-show-nav" : ""}`}>
                  <button className="header-nav-close" onClick={() => setShowNav(false)}>X CLOSE</button>

                  <ul className="header-nav-items">
                    <li className={`${_.includes(locationProps.location.pathname, '/athletes') ? 'header-active-nav' : ''}`}>
                      <Link to="/athletes">
                        Athletes
                      </Link>
                      {renderUnderline(_.includes(locationProps.location.pathname, '/athletes'))}
                    </li>
                    <li className={`${_.includes(locationProps.location.pathname, '/organizations') ? 'header-active-nav' : ''}`}>
                      <Link to="/organizations">
                        Companies
                      </Link>
                      {renderUnderline(_.includes(locationProps.location.pathname, '/organizations'))}
                    </li>
                    <li className={`${_.includes(locationProps.location.pathname, '/how-it-works') ? 'header-active-nav' : ''}`}>
                      <Link to="/how-it-works">
                        How it works
                      </Link>
                      {renderUnderline(_.includes(locationProps.location.pathname, '/how-it-works'))}
                    </li>
                    <li className={`${_.includes(locationProps.location.pathname, '/made-by') ? 'header-active-nav' : ''}`}>
                      <Link to="/made-by">
                        Cool Things We’ve Done
                      </Link>
                      {renderUnderline(_.includes(locationProps.location.pathname, '/made-by'))}
                    </li>
                    <li className={`${_.includes(locationProps.location.pathname, '/about') ? 'header-active-nav' : ''}`}>
                      <Link to="/about">
                        About
                      </Link>
                      {renderUnderline(_.includes(locationProps.location.pathname, '/about'))}
                    </li>
                    <li className="header-sign-in">
                      <a href="/app/login">
                        Sign In
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </header>
          )
        }
      }
    </Location>
  )
}

Header.defaultProps = {
  siteTitle: ``,
}
export default Header
