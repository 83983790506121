import React from "react"
import "./PrimaryButton.css"
import CircularProgress from '@material-ui/core/CircularProgress';
/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const PrimaryButton = ({ children, loading, onClick, disabled }) => {

  return (
    <button
      className="PrimaryButton-button"
      disabled={disabled}
      onClick={() => {
        if (!loading && onClick && !disabled) {
          onClick();
        }
      }}
    >
      {
        loading ?
        <span style={{ color: "#fff" }}><CircularProgress size={18} color='inherit' /></span> :
        children
      }
    </button>
  )
}

export default PrimaryButton
